import { restartChatWithNewUser } from '@states/slices/chatbotSlice';
import { RootState } from '@/states/store';
import { useDispatch, useSelector } from 'react-redux';
import { MdRestartAlt, MdAdd } from 'react-icons/md';
import Tooltip from '@components/Tooltip';

function RestartButton() {
  const { currentLanguage, isAddNewConversationEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  const dispatch = useDispatch();

  return (
    <Tooltip content={currentLanguage.new_chat.button}>
      <div
        className='restart-button'
        onClick={() => {
          dispatch(restartChatWithNewUser(currentLanguage));
        }}
      >
        {isAddNewConversationEnabled ? <MdAdd /> : <MdRestartAlt />}
      </div>
    </Tooltip>
  );
}

export default RestartButton;
