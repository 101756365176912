import { RootState } from '@/states/store';
import { useSelector } from 'react-redux';
import ChatInput from '@components/ChatBottom/ChatInput';
import EmojiPickerButton from '@components/ChatBottom/EmojiPickerButton';
import FileUploadButton from '@components/ChatBottom/FileUploadButton';
import SendMessageButton from '@components/ChatBottom/SendMessageButton';

function DefaultChatBottom() {
  const { isFileSendEnabled, isEmojiSendEnabled } = useSelector(
    (state: RootState) => state.settings
  );

  return (
    <>
      {isFileSendEnabled && <FileUploadButton />}
      {isEmojiSendEnabled && <EmojiPickerButton />}
      <ChatInput />
      <SendMessageButton />
    </>
  );
}

export default DefaultChatBottom;
