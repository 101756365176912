import axios from 'axios';
import {  BACKEND_URL }  from './constants';

const apiClient = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'ngrok-skip-browser-warning': 'true',
  },
});

export default apiClient;
