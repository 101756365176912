import conversationService from '@/services/conversationService';
import { ConversationDataProps } from '@/utils/types';
import '@styles/pages/Conversation.scss';
import { useEffect, useState } from 'react';
import { formatDate } from '@utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/states/store';
import { toast } from 'react-toastify';
import StateMessage from '@/components/Conversation/StateMessage';
import ConversationList from '@/components/Conversation/ConversationList';
import { useSearchParams } from 'react-router-dom';

function Conversation() {
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get('conversationId');

  const [data, setData] = useState<ConversationDataProps>({
    messages: [],
    updatedAt: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { currentLanguage } = useSelector((state: RootState) => state.settings);

  async function fetchConversationHistory(id: string) {
    try {
      setIsLoading(true);
      const { messages, updated_at: updatedAt } =
        await conversationService.getConversationById(id);
      setData({ messages, updatedAt });
    } catch (error) {
      toast.error(currentLanguage.conversation.error_fetching);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (conversationId) {
      fetchConversationHistory(conversationId);
    }
  }, [conversationId]);

  function renderContent() {
    if (isLoading) {
      return <StateMessage message={currentLanguage.conversation.loading} />;
    }

    if (!data.messages?.length) {
      return (
        <StateMessage message={currentLanguage.conversation.error_fetching} />
      );
    }

    return <ConversationList messages={data.messages} />;
  }

  return (
    <section className='conversation-container'>
      <div className='date'>
        {data?.updatedAt && <span>{formatDate(data.updatedAt)}</span>}
      </div>
      {renderContent()}
    </section>
  );
}

export default Conversation;
