import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Conversation from './pages/Conversation';
import './App.scss';
import useCompanyConfig from './hooks/useCompanyConfig';
import { useTrackCloseEvent } from './hooks/useBeforeUnload';
import { BASENAME, USE_NGINX_ENABLED } from './utils/constants';

function App() {
  useCompanyConfig();

  useTrackCloseEvent();

  return (
    <Router basename={USE_NGINX_ENABLED ? `/${BASENAME}bot` : ''}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/conversation' element={<Conversation />} />
      </Routes>
    </Router>
  );
}

export default App;
