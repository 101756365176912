import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { socket } from '@utils/socket';
import {
  ChatMessage,
  LiveMessageEventPayload,
  StreamResponse,
} from '@utils/types';
import {
  addToLatestResponse,
  setChatHistory,
  setChatStatus,
  setIsStreamFinished,
  setWaitingForBotReply,
} from '@states/slices/chatbotSlice';
import { toast } from 'react-toastify';
import { getFormattedTime } from '@utils/utils';
import { MSW_ENABLED } from '@utils/constants';

function useWebSockets() {
  const dispatch = useDispatch();
  const { currentUser, currentConversation, chatHistory } = useSelector(
    (state: RootState) => state.chatbot
  );
  const [liveMessage, setLiveMessage] = useState<ChatMessage | null>(null);

  useEffect(() => {
    if (!MSW_ENABLED) {
      connectWithRetry();
    }

    socket.on('connect', () => {
      connectToConversation();
    });

    // more logging for disconnection and reconnection

    socket.on('disconnect', (reason) => {
      console.error('Disconnected:', reason);
      if (reason === 'io server disconnect') {
        // connect manually after server disconnect
        socket.connect();
      }
    });

    socket.on('reconnect', (attemptNumber) => {
      console.info('Reconnected after', attemptNumber, 'attempts');
      connectToConversation();
    });

    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      toast.error('Connection Error. Please try again.');
      setTimeout(connectWithRetry, 2000);
    });

    socket.on('reconnect_attempt', (attemptNumber) => {
      console.info('Reconnect attempt:', attemptNumber);
    });

    socket.on('reconnect_error', (error) => {
      console.error('Reconnect Error:', error);
    });

    socket.on('reconnect_failed', () => {
      console.error('Reconnection failed');
    });

    socket.on('send_live_message', (data: LiveMessageEventPayload) => {
      sendLiveMessage(data);
    });

    socket.on('bot_response', (response: StreamResponse) => {
      streamBotResponse(response);
    });

    socket.on('conversation_joined_by_agent', (data: any) => {
      console.info('conversation_joined_by_agent', data);
      dispatch(setChatStatus(data.status));
    });

    return () => {
      console.info('Disconnecting...');
      socket.off('connect');
      socket.off('send_live_message');
      socket.off('bot_response');
      socket.off('conversation_joined_by_agent');
      socket.off('connect_error');
      socket.off('reconnect_attempt');
      socket.off('reconnect_error');
      socket.off('reconnect_failed');
      socket.disconnect();
    };
  }, [currentConversation, currentUser]);

  useEffect(() => {
    if (liveMessage) {
      const newChatHistory = [...chatHistory, liveMessage];
      dispatch(setChatHistory(newChatHistory));
      setLiveMessage(null);
    }
  }, [liveMessage]);

  function connectWithRetry() {
    console.info('Attempting to connect to websocket');
    if (currentConversation === '') {
      console.info('No conversation to connect to. Retrying...');
      return;
    }
    socket.connect();
  }

  function connectToConversation() {
    console.info('Connected to websocket', socket.id);
    if (currentUser && currentConversation !== '') {
      socket.emit(
        'join_conversation',
        {
          user_id: currentUser,
          conversation_id: currentConversation,
        },
        (response: any) => {
          if (response.status === 'joined') {
            console.info(
              'Successfully joined conversation:',
              response.conversation_id
            );
          } else if (response.status === 'error') {
            console.error('Error joining conversation:', response.message);
            toast.error(response.message);
          }
        }
      );
    }
  }

  function sendLiveMessage(data: LiveMessageEventPayload) {
    if (data.messages.length === 0) return;
    if (data.conversation_id !== currentConversation) return;
    console.info('Updating conversation with live chat...');
    const formattedTime = getFormattedTime();
    const newLiveMessage: ChatMessage = {
      id: data.messages[data.messages.length - 1].id,
      text: data.messages[data.messages.length - 1].text,
      pov: data.messages[data.messages.length - 1].pov,
      time: formattedTime,
    };
    setLiveMessage(newLiveMessage);
    dispatch(setWaitingForBotReply(false));
  }

  function streamBotResponse(response: StreamResponse) {
    dispatch(setIsStreamFinished(false));
    const formattedTime = getFormattedTime();
    dispatch(
      addToLatestResponse({
        id: '',
        pov: 'bot',
        text: response.text,
        time: formattedTime,
      })
    );
    if (response.is_stream_finished) {
      dispatch(setIsStreamFinished(true));
    }
  }
}

export default useWebSockets;
