import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PreviewState, PreviewType } from '@utils/types';
import { checkLargeFile, getFileType } from '@utils/files';
import utilsService from '@services/utilsService';
import { toast } from 'react-toastify';

export const displayPreview = createAsyncThunk(
  'filePreview/displayPreview',
  async (file: File, { rejectWithValue }) => {
    try {
      const fileType = getFileType(file);
      if (!fileType) {
        return rejectWithValue('Invalid file type');
      }

      const isLargeFile = checkLargeFile(file);
      if (isLargeFile) {
        return rejectWithValue('File size exceeds 1MB');
      }

      const formData = new FormData();
      formData.append('file', file);
      const url = await utilsService.uploadFile(formData);
      return { url, fileType };
    } catch (error) {
      toast.error(
        'An error occurred while uploading the file. Please try again.'
      );
      return rejectWithValue(error);
    }
  }
);

const initialState: PreviewState = {
  showPreview: false,
  showUploadButton: true,
  previewTitle: 'Upload Image',
  previewType: 'image',
  url: '',
  isLoading: false,
};

export const filePreviewSlice = createSlice({
  name: 'filePreview',
  initialState,
  reducers: {
    setShowPreview: (state, action: PayloadAction<boolean>) => {
      state.showPreview = action.payload;
    },
    setShowUploadButton: (state, action: PayloadAction<boolean>) => {
      state.showUploadButton = action.payload;
    },
    setPreviewTitle: (state, action: PayloadAction<string>) => {
      state.previewTitle = action.payload;
    },
    setPreviewType: (state, action: PayloadAction<PreviewType>) => {
      state.previewType = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(displayPreview.pending, (state) => {
        state.showPreview = true;
        state.isLoading = true;
      })
      .addCase(displayPreview.fulfilled, (state, action) => {
        if (state.showPreview) {
          state.url = action.payload.url;
          state.previewType = action.payload.fileType as PreviewType;
          state.showUploadButton = true;
          state.previewTitle = '';
        }
        state.isLoading = false;
      })
      .addCase(displayPreview.rejected, (state) => {
        state.isLoading = false;
        state.showPreview = false;
      });
  },
});

export const {
  setShowPreview,
  setShowUploadButton,
  setPreviewTitle,
  setUrl,
  setPreviewType,
  setIsLoading,
} = filePreviewSlice.actions;

export default filePreviewSlice.reducer;
