import { ConversationFlowType } from "@/utils/types";

export const initialConversationFlow: ConversationFlowType[] = [
  {
    id: 1,
    text: "Hi, how are you? Our experts have checked your account and found that now is a great time for you to open a Certificate of Deposit. Would you like to hear more details about our CD programs?",
    pov: "bot"
  },
  {
    id: 2,
    text: "",
    file: "https://res.cloudinary.com/dxmewvier/image/upload/v1732987137/pdf-sample.pdf",
    pov: "user"
  },
  {
    id: 3,
    text: "",
    file: "https://res.cloudinary.com/dxmewvier/image/upload/v1543582432/samples/cloudinary-icon.png",

    pov: "user"
  },
  {
    id: 4,
    text: "I will like to deposit $5000 over a period of 6 months",
    file: "https://insaitsstorageaccount.blob.core.windows.net/chatbotfiles/8a52235b-674b-4234-a631-52c9bbd2185a-everbank-rate-box.jpg?se=2025-09-23T10%3A52%3A04Z&sp=r&sv=2023-11-03&sr=b&sig=MmOJfhAbrYy7FpeHtLPSh%2BVs%2BbWGChxEANA4j9YEnyo%3D",
    pov: "user"
  },
  {
    id: 5,
    text: "Is this deposit coming from outside the bank (new money) or from within the bank?",
    pov: "bot"
  },
  {
    id: 6,
    text: "this is new money",
    file: "https://res.cloudinary.com/dxmewvier/image/upload/v1732987137/pdf-sample.pdf",
    pov: "bot"
  },
  {
    id: 7,
    text: "Great! Let's discuss your withdrawal preferences. Would you like the option for early withdrawal? Please note that choosing early withdrawal will result in a lower interest rate.",
    pov: "bot"
  },
  {
    id: 8,
    text: "early withdrawal",
    pov: "user"
  },
  {
    id: 9,
    text: "Great. We have two programs that offer early withdrawal options:\n\n1. **13 Months USD with Early Withdrawal**: This program has early exit points at 3, 6, and 9 months. The interest rates vary based on the withdrawal point, with a maximum of 2.45% at maturity. Since you are bringing new money, you will get an additional 0.5% interest rate.\n\n2. **6 Months USD with Monthly Withdrawals**: This program allows for monthly withdrawals with an interest rate of 1.909% at maturity. With the new money bonus, your interest rate will be 2.409%.\n\nWhich program would you prefer?",
    pov: "bot"
  },
  {
    id: 10,
    text: "First option",
    pov: "user"
  },
  {
    id: 11,
    text: "Based on the \"6 Months USD with Monthly withdrawals\" program, your total amount after interest will be $5,054.88, with a return of $54.88.\n\nWould you like to proceed with this program? Please confirm by saying \"yes\".",
    pov: "bot"
  },
  {
    id: 12,
    text: "Yes",
    pov: "user"
  },
  {
    id: 13,
    text: "Great! Let's review the details:\n\n- **Program Name:** 6 Months USD with Monthly withdrawals\n- **Amount:** $5,000\n- **Duration:** 6 months\n- **Early Withdrawal:** Yes\n- **New Money:** Yes\n\nThe total amount after interest will be $5,054.88, with a return of $54.88.\n\nShall we proceed to open the account? Please confirm by saying \"yes\".",
    pov: "bot"
  },
  {
    id: 14,
    text: "yes",
    pov: "user"
  },
  {
    id: 15,
    text: "Thank you for confirming! To complete the process, please visit [www.insait.com/complete_process](www.insait.com/complete_process).\n\nThank you for choosing Bank Leumi! If you have any further questions, feel free to ask.",
    pov: "bot"
  }
]