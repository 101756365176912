import { toast } from 'react-toastify';
import { DragEvent } from 'react';
import { AppDispatch } from '@/states/store';
import { displayPreview } from '@/states/slices/filePreviewSlices';
import { ChatLanguage } from './types';
import { MAX_FILE_SIZE } from './constants';


export function getFileSrc(file: File | string | null): string {
  if (file instanceof File) {
    return URL.createObjectURL(file);
  } else if (typeof file === 'string') {
    return file;
  } else {
    return '';
  }
}

export function getFileType(file: File | string) {
  const validTypes = {
    image: ['jpeg', 'png', 'gif', 'jpg'],
    video: ['mp4', 'webm', 'ogg', 'quicktime', 'mov', 'mpeg', 'avi'],
    pdf: ['pdf'],
  };

  const fileExtension =
    file instanceof File
      ? file.type.split('/').pop()
      : file.split('.').pop()?.split('?')[0];

  if (!fileExtension) {
    toast.error('Invalid file format');
    return;
  }
  for (const [type, extensions] of Object.entries(validTypes)) {
    if (extensions.includes(fileExtension)) {
      return type;
    }
  }
  toast.error('Invalid file format');
}

function getFileSizeFormat(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = bytes / Math.pow(1024, i);
  return `${size % 1 === 0 ? size.toFixed(0) : size.toFixed(2)} ${sizes[i]}`;
}

export function checkLargeFile(file: File) {
  if (file.size > MAX_FILE_SIZE) {
    toast.error(`File size exceeds ${getFileSizeFormat(MAX_FILE_SIZE)}`);
    return true;
  }
  return false;
}

export const fileTypeHandlers = [
  {
    fileType: 'image',
    validation: (file: File) => getFileType(file) === 'image',
    errorMessage: 'Invalid file format, please upload a PNG or JPEG file.',
  },
  {
    fileType: 'video',
    validation: (file: File) => getFileType(file) === 'video',
    errorMessage: 'Invalid file format. Please upload an MP4 or WEBM file',
  },
];

export function handleFileDrop(
  event: DragEvent<HTMLDivElement>,
  dispatch: AppDispatch,
  isUploadEnabled: boolean,
  currentLanguage: ChatLanguage
) {
  event.preventDefault();
  if (!isUploadEnabled) {
    toast.error(currentLanguage.upload.disabled);
    return;
  }
  const file = event.dataTransfer.files?.[0];
  if (file) {
    dispatch(displayPreview(file));
  }
}

export function handleDragOver(event: DragEvent<HTMLDivElement>) {
  event.preventDefault();
}
