import HeaderBar from '@/components/ChatHeader/HeaderBar'
import useCurrentSkin from '@/hooks/useCurrentSkin'
import { RootState } from '@/states/store'
import { useSelector } from 'react-redux'

function EtoroHeader () {
  const currentSkin = useCurrentSkin()
  const { isEmbeddedModeEnabled } = useSelector(
    (state: RootState) => state.settings
  )

  return (
    <header>
      {isEmbeddedModeEnabled === false && (
        <div className={`default-header ${currentSkin.css.header}`}>
          <div />
          <HeaderBar />
        </div>
      )}
    </header>
  )
}

export default EtoroHeader
