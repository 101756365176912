import { useEffect } from 'react';
import { setTextDirection } from '@utils/utils';
import { ModalProps } from './Modal.type';
import './Modal.scss';

function Modal({
  title,
  isOpen,
  onClose,
  children,
  stickyContent,
}: ModalProps) {
  useEffect(() => {
    setTextDirection('.modal-content', 'ltr');
  }, [isOpen]);

  return isOpen ? (
    <>
      <div className='modal-overlay' onClick={onClose}>
        <div className='modal-content' onClick={(e) => e.stopPropagation()}>
          <div className='modal-header'>
            <h2 className='modal-title'>{title}</h2>
            <button 
              className='modal-close-button' 
              onClick={onClose} 
              aria-label='Close modal'
            >
              &times;
            </button>
          </div>
          <div className='modal-body'>{children}</div>
          {stickyContent && (
            <div className='modal-sticky-content'>{stickyContent}</div>
          )}
        </div>
      </div>
    </>
  ) : null;
}

export default Modal;
