import { ConstantsType } from './types';

export const VITE_MODE = import.meta.env.MODE;
export const MSW_ENABLED = import.meta.env.VITE_MSW_ENABLED === '1' || false;

const rootConstants: ConstantsType = {
  CONFIGURATIONS_SERVICE_URL:
    import.meta.env.VITE_CONFIGURATIONS_SERVICE_URL || 'http://localhost:4000',
  MAX_FILE_SIZE: (import.meta.env.VITE_MAX_FILE_SIZE ?? 10) * 1024 * 1024,
  INPUT_LIMIT_BY_CHAR: import.meta.env.VITE_INPUT_LIMIT_BY_CHAR ?? 1000,
  RESPONSE_STREAMING_ENABLED:
    import.meta.env.VITE_RESPONSE_STREAMING_ENABLED === '1',
  LOCAL_FILE_STORAGE_ENABLED:
    import.meta.env.VITE_LOCAL_FILE_STORAGE_ENABLED === '1',
  INSAIT_LANDING_PAGE: 'https://insait.io/landing',
  CURRENT_GIT_SHA: import.meta.env.VITE_CURRENT_GIT_SHA,
  USE_NGINX_ENABLED: import.meta.env.VITE_USE_NGINX_ENABLED === '1',
  BASENAME: import.meta.env.VITE_BASENAME,
  BACKEND_PORT: import.meta.env.VITE_BACKEND_PORT,
  HOST: import.meta.env.VITE_BASENAME
    ? `${window.location.hostname}/${import.meta.env.VITE_BASENAME}`
    : `${window.location.hostname}${
        import.meta.env.VITE_USE_NGINX_ENABLED === '1' ? '/' : ''
      }`,
  TENANT: import.meta.env.VITE_TENANT,
};

const dependentConstants: ConstantsType = {
  BACKEND_URL: rootConstants.USE_NGINX_ENABLED
    ? `https://${rootConstants.HOST}api`
    : `http://${rootConstants.HOST}:${rootConstants.BACKEND_PORT}`,
};

const constants = {
  ...rootConstants,
  ...dependentConstants,
};

if (VITE_MODE === 'production') {
  Object.keys(constants).forEach((key) => {
    constants[key] = window._env_[key] ? window._env_[key] : constants[key];
  });
}

export const {
  CONFIGURATIONS_SERVICE_URL,
  MAX_FILE_SIZE,
  INPUT_LIMIT_BY_CHAR,
  RESPONSE_STREAMING_ENABLED,
  LOCAL_FILE_STORAGE_ENABLED,
  INSAIT_LANDING_PAGE,
  CURRENT_GIT_SHA,
  USE_NGINX_ENABLED,
  BASENAME,
  BACKEND_PORT,
  HOST,
  BACKEND_URL,
  TENANT,
} = constants;
