import { BACKEND_URL } from '@utils/constants';
import apiClient from '@utils/axios';

const eventsService = {
  async handleCloseEvent(payload: any) {
    try {
      navigator.sendBeacon(`${BACKEND_URL}/events/close`, payload);
    } catch (error) {
      throw error;
    }
  },
  async handleLoginEvent(user_id: string) {
    try {
      const response = await apiClient.post(`${BACKEND_URL}/events/login`, {
        user_id,
      });
      const { ai_response: botReply } = response.data;
      return botReply;
    } catch (error) {
      throw error;
    }
  },
  async handleIdleEvent(user_id: string, conversation_id: string) {
    try {
      const response = await apiClient.post(`${BACKEND_URL}/events/idle`, {
        user_id,
        conversation_id,
      });
      return response.data.ai_response;
    } catch (error) {
      throw error;
    }
  },
};

export default eventsService;
