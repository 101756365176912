import Insait from '@assets/images/insait-logo.svg';
import './Watermark.scss';
import { INSAIT_LANDING_PAGE } from '@/utils/constants';
import { RootState } from '@/states/store';
import { useSelector } from 'react-redux';

function Watermark() {
  const { footerLogo } = useSelector((state: RootState) => state.settings);
  return (
    <div className='watermark-wrapper'>
      <a href={INSAIT_LANDING_PAGE} target='_blank' rel='noreferrer noopener'>
        <div>POWERED BY </div>
        <img src={Insait} alt='insait logo' className='insait-logo' />
        {footerLogo && (
          <>
            <div>FOR</div>
            <img
              src={footerLogo}
              alt='footer watermark logo'
              className='footer-logo'
            />
          </>
        )}
      </a>
    </div>
  );
}

export default Watermark;
