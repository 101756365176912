import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import LoadingAnimation from '@components/LoadingAnimation';
import PdfPreview from '@components/PdfPreview';
import { PreviewType } from '@utils/types';
import './FilePreview.scss';

function FilePreview() {
  function renderFileContent(fileType: PreviewType, url: string) {
    switch (fileType) {
      case 'image':
        return <img src={url} alt='image' />;
      case 'video':
        return <video src={url} controls />;
      case 'pdf':
        return <PdfPreview url={url} thumbnail={false} />;
      default:
        return <div>Unsupported File Type</div>;
    }
  }

  const { previewType, url, isLoading } = useSelector(
    (state: RootState) => state.filePreview
  );
  return (
    <div className='uploaded-file-box'>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {previewType === 'pdf' ? (
            <>{renderFileContent(previewType, url)}</>
          ) : (
            <TransformWrapper>
              <TransformComponent
                contentStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {renderFileContent(previewType, url)}
              </TransformComponent>
            </TransformWrapper>
          )}
        </>
      )}
    </div>
  );
}

export default FilePreview;
