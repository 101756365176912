import { useEffect, useState } from 'react';

export function useAutoScroll(
  chatContainerRef: React.RefObject<HTMLDivElement>
) {
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);

  function handleScroll() {
    if (!chatContainerRef.current) return;

    const isAtBottom =
      chatContainerRef.current.scrollHeight -
        chatContainerRef.current.scrollTop -
        chatContainerRef.current.clientHeight <
      10;

    setIsUserAtBottom(isAtBottom);
  }

  useEffect(function () {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return function cleanup() {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  function scrollToBottom() {
    if (chatContainerRef.current && isUserAtBottom) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }

  return scrollToBottom;
}
