import { QuestionContentProps } from './SugggestedQuestion.type'

function QuestionContent ({
  questions,
  containerClassName,
  selected,
  onClick,
  sortByLength = false
}: QuestionContentProps) {
  
  const questionsToRender = sortByLength
    ? questions.slice().sort((a, b) => a.length - b.length)
    : questions

  return (
    <div className={containerClassName}>
      <div className={`responses-container size-${questions.length}`}>
        {questionsToRender.map((question, index) => (
          <button
            key={index}
            className={`suggested-response-button ${
              selected ? (selected === question ? 'active' : 'inactive') : ''
            }`}
            onClick={() => onClick && onClick(question)}
            title={question}
          >
            {question}
          </button>
        ))}
      </div>
    </div>
  )
}

export default QuestionContent
