import DefaultHeader from '@components/ChatHeader/DefaultHeader';
import DefaultOpenButton from '@components/DefaultOpenButton';
import LeumiHeader from '@skins/leumi/components/LeumiHeader';
import AlternateOpenButton from '@skins/alternate/components/AlternateOpenButton';
import DefaultChatBottom from '@components/ChatBottom/DefaultChatBottom';
import AlternateChatBottom from '@skins/alternate/components/AlternateChatBottom';
import { UiSkin } from '@utils/types';
import sendArrow from '@assets/images/send-arrow.svg';
import emojiIcon from '@assets/images/emoji.svg';
import fileAttachment from '@assets/images/file-attachment.svg';
import FileAttachmentAlternate from '@skins/alternate/assets/file-icon.png';
import emojiIconAlternate from '@skins/alternate/assets/emoji-icon.png';
import sendArrowAlternate from '@skins/alternate/assets/send-icon.png';
import sendArrowOutline from '@skins/etoro/assets/send-icon.svg';
import sendArrowLeumi from '@skins/leumi/assets/send-arrow-leumi.svg';
import emojiLeumi from '@skins/leumi/assets/emoji-leumi.svg';
import fileAttachmentLeumi from '@skins/leumi/assets/file-attachment-leumi.svg';
import closeIcon from '@skins/alternate/assets/close-modal.svg';
import thinCloseIcon from '@skins/etoro/assets/close-modal.svg'
import EtoroHeader from './etoro/components/EtoroHeader';

const defaultSkin: UiSkin = {
  components: {
    header: DefaultHeader,
    chatBottom: DefaultChatBottom,
    openButton: DefaultOpenButton,
  },
  css: {
    mainContainer: 'main-container',
    header: 'default-header',
    chatContainer: 'chatting-container',
    chatbotArrow: 'chatbot-arrow',
    bottomContainer: 'bottom-container',
  },
  flags: {
    closeIcon: false,
    openButtonGradient: true,
    headerGradient: true,
    onlineNowEnabled: true,
    chatbotLogoBorder: true,
    chatbotReplyGradient: true,
    chatbotSendArrowGradient: true,
    buttonsInsideInput: false,
  },
  assets: {
    sendArrow: sendArrow,
    emojiIcon: emojiIcon,
    fileAttachment: fileAttachment,
    closeIcon: closeIcon,
  },
};

const default2Skin: UiSkin = {
  components: {
    header: DefaultHeader,
    chatBottom: DefaultChatBottom,
    openButton: AlternateOpenButton,
  },
  css: {
    mainContainer: 'main-container',
    header: 'default-header',
    chatContainer: 'chatting-container',
    chatbotArrow: 'chatbot-arrow',
    bottomContainer: 'bottom-container',
  },
  flags: {
    closeIcon: false,
    openButtonGradient: true,
    headerGradient: true,
    onlineNowEnabled: true,
    chatbotLogoBorder: true,
    chatbotReplyGradient: true,
    chatbotSendArrowGradient: true,
    buttonsInsideInput: false,
  },
  assets: {
    sendArrow: sendArrow,
    emojiIcon: emojiIcon,
    fileAttachment: fileAttachment,
    closeIcon: closeIcon,
  },
};

const alternateSkin: UiSkin = {
  components: {
    header: DefaultHeader,
    chatBottom: AlternateChatBottom,
    openButton: AlternateOpenButton,
  },
  css: {
    mainContainer: 'main-container main-container-alternate',
    header: 'alternate-header',
    chatContainer: 'chatting-container chatting-container-alternate',
    chatbotArrow: 'chatbot-arrow',
    bottomContainer: 'bottom-container bottom-container-alternate',
  },
  flags: {
    closeIcon: true,
    openButtonGradient: false,
    headerGradient: false,
    onlineNowEnabled: false,
    chatbotLogoBorder: false,
    chatbotReplyGradient: false,
    chatbotSendArrowGradient: false,
    buttonsInsideInput: true,
  },
  assets: {
    sendArrow: sendArrowAlternate,
    emojiIcon: emojiIconAlternate,
    fileAttachment: FileAttachmentAlternate,
    closeIcon: closeIcon,
  },
};

const leumiSkin: UiSkin = {
  components: {
    header: LeumiHeader,
    chatBottom: DefaultChatBottom,
    openButton: DefaultOpenButton,
  },
  css: {
    mainContainer: 'main-container',
    header: 'leumi-header',
    chatContainer: 'chatting-container chatting-container-leumi',
    chatbotArrow: '',
    bottomContainer: 'bottom-container bottom-container-leumi',
  },
  flags: {
    closeIcon: false,
    openButtonGradient: true,
    headerGradient: false,
    onlineNowEnabled: true,
    chatbotLogoBorder: true,
    chatbotReplyGradient: false,
    chatbotSendArrowGradient: false,
    buttonsInsideInput: false,
  },
  assets: {
    sendArrow: sendArrowLeumi,
    emojiIcon: emojiLeumi,
    fileAttachment: fileAttachmentLeumi,
    closeIcon: closeIcon,
  },
};

const etoroSkin: UiSkin = {
  components: {
    header: EtoroHeader,
    chatBottom: AlternateChatBottom,
    openButton: AlternateOpenButton,
  },
  css: {
    mainContainer: 'main-container main-container-etoro',
    header: 'etoro-header',
    chatContainer: 'chatting-container chatting-container-etoro',
    bottomContainer: 'bottom-container bottom-container-alternate etoro',
  },
  flags: {
    closeIcon: true,
    openButtonGradient: false,
    headerGradient: false,
    onlineNowEnabled: false,
    chatbotLogoBorder: false,
    chatbotReplyGradient: false,
    chatbotSendArrowGradient: false,
    buttonsInsideInput: true,
  },
  assets: {
    sendArrow: sendArrowOutline,
    emojiIcon: emojiIconAlternate,
    fileAttachment: FileAttachmentAlternate,
    closeIcon: thinCloseIcon,
  },
};

export const skins = {
  default: defaultSkin,
  default2: default2Skin,
  alternate: alternateSkin,
  leumi: leumiSkin,
  etoro: etoroSkin
};
