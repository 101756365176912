import apiClient from '@utils/axios';
import { BACKEND_URL } from '@utils/constants';

const validationService = {
  async validateIframeOrigin(
    parentDomain: string,
    directDomain: string,
    url: string
  ) {
    try {
      const response = await apiClient.post(
        `${BACKEND_URL}/validation/iframe`,
        {
          parent_domain: parentDomain,
          direct_domain: directDomain,
          url: url,
        }
      );
      if (response.status !== 200) throw new Error('Invalid iframe origin');
      const {
        valid: isOriginValid,
        bot_variant: botVariant,
      }: {
        valid: boolean;
        bot_variant: string;
      } = response.data;
      return { isOriginValid, botVariant };
    } catch (error) {
      console.error('Error validating iframe origin', error);
      return { isOriginValid: false, botVariant: null };
    }
  },
};

export default validationService;
