import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import useCurrentSkin from '@hooks/useCurrentSkin';

function ChatBottomContainer() {
  const { isFreemiumModeEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  const currentSkin = useCurrentSkin();

  const ChatBottom = currentSkin.components.chatBottom;

  return (
    <div
      className={`${currentSkin.css.bottomContainer} ${
        isFreemiumModeEnabled ? 'with-watermark' : ''
      }`}
    >
      <ChatBottom />
    </div>
  );
}

export default ChatBottomContainer;
