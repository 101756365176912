import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkinName, SkinsState } from '../../utils/types';

const initialState: SkinsState = {
  currentSkinName: 'default',
};

// We don't need this slice as it is. Either remove it or add more functionality (botImage for example..).
export const skinsSlice = createSlice({
  name: 'skins',
  initialState,
  reducers: {
    setCurrentSkin: (state, action: PayloadAction<SkinName>) => {
      state.currentSkinName = action.payload;
    },
  },
});

export const { setCurrentSkin } = skinsSlice.actions;
export default skinsSlice.reducer;
