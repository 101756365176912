import apiClient from '@/utils/axios';
import { BACKEND_URL } from '@utils/constants';
import { TrackLinkData } from '@utils/types';
import axios from 'axios';

const analyticsService = {
  async sendLeadToLeaderPixel(leadData: any) {
    console.info('Sending lead to Leader Pixel', leadData);
    try {
      const response = await axios.post(
        'https://www.leader.online/leader/post/post.php',
        leadData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: false,
        }
      );
      if (!response.data || response.data.status !== 'true') {
        throw new Error('Request failed. Please confirm the data sent.');
      }
      return response.data;
    } catch (error) {
      console.error('Error in request to Leader Pixel', error);
    }
  },
  async saveLinkClicked(data: TrackLinkData) {
    try {
      const response = await apiClient.post(`${BACKEND_URL}/links/`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default analyticsService;
