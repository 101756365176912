import { useDispatch, useSelector } from 'react-redux';
import useCurrentSkin from '@hooks/useCurrentSkin';
import {
  isStreamingWaitingInProgress,
  setIsInputSent,
} from '@states/slices/chatbotSlice';
import { getGradientBackground } from '@utils/utils';
import { RootState } from '@states/store';
import './SendMessageButton.scss';

function SendMessageButton() {
  const { chatStatus, userInput } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { color1, color2 } = useSelector((state: RootState) => state.settings);
  const isInputStreamingDisabled = useSelector(isStreamingWaitingInProgress);

  const dispatch = useDispatch();
  const currentSkin = useCurrentSkin();

  return (
    <button
      className='send-message'
      onClick={() => {
        dispatch(setIsInputSent(true));
      }}
      style={
        currentSkin.flags.chatbotSendArrowGradient
          ? { background: getGradientBackground({ color1, color2 }) }
          : {}
      }
      disabled={
        isInputStreamingDisabled ||
        chatStatus === 'TRANSFERRED' ||
        userInput === ''
      }
      aria-label='Send message'
    >
      <img src={currentSkin.assets.sendArrow} alt='Send message icon' />
    </button>
  );
}

export default SendMessageButton;
