import { StateMessageProps } from "./StateMessage.type"

function StateMessage ({ message }: StateMessageProps) {
  return (
    <div className='state-message'>
      <span>{message}</span>
    </div>
  )
}

export default StateMessage