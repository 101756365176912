import eventsService from '@/services/eventsService';
import { RootState } from '@/states/store';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export function useTrackCloseEvent() {
  const currentUser = useSelector(
    (state: RootState) => state.chatbot.currentUser
  );
  const currentConversation = useSelector(
    (state: RootState) => state.chatbot.currentConversation
  );
  const isMobile = useSelector(
    (state: RootState) => state.settings.isMobileModeEnabled
  );

  async function handleCloseEvent() {
    if (currentUser && currentConversation) {
      const payload = JSON.stringify({
        user_id: currentUser,
        conversation_id: currentConversation,
      });
      await eventsService.handleCloseEvent(payload);
    }
  }

  const isConversationStarted = Boolean(currentUser && currentConversation);

  const handlerRef = useRef(handleCloseEvent);
  useEffect(() => {
    handlerRef.current = handleCloseEvent;
  }, [currentUser, currentConversation]);

  useEffect(() => {
    if (isConversationStarted) {
      const listener = () => {
        if (handlerRef.current && !isMobile) {
          handlerRef.current();
        }
      };

      const visibilityChangeListener = () => {
        if (
          handlerRef.current &&
          document.visibilityState === 'hidden' &&
          isMobile
        ) {
          handlerRef.current();
        }
      };

      window.addEventListener('beforeunload', listener);
      document.addEventListener('visibilitychange', visibilityChangeListener);

      return () => {
        window.removeEventListener('beforeunload', listener);
        document.removeEventListener(
          'visibilitychange',
          visibilityChangeListener
        );
      };
    }
  }, [isConversationStarted, isMobile]);
}
