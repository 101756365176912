import { LeaderCookies } from './types';

export function getLeaderCookies(cookies: string): LeaderCookies {
  const allCookies = cookies
    .split('; ')
    .reduce((acc: { [key: string]: string }, cookie: string) => {
      const [name, value] = cookie.split('=');
      acc[name] = decodeURIComponent(value);
      return acc;
    }, {});

  const leaderData = Object.keys(allCookies).reduce(
    (acc: LeaderCookies, key) => {
      if (key.startsWith('cc_') && allCookies[key]) {
        const newKey = key.substring(3);
        acc[newKey] = allCookies[key];
      }
      return acc;
    },
    {}
  );

  const currentUrl = new URL(decodeURIComponent(allCookies.theurl));
  const baseUrl = currentUrl.origin + currentUrl.pathname;

  leaderData.theurl = baseUrl;
  leaderData.page_title = allCookies.page_title || document.title;
  leaderData.campaignid = allCookies.campaignid;
  return leaderData;
}
