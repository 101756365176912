import { SettingsFormData } from '@utils/types';
import apiClient from '@utils/axios';
import { BACKEND_URL }  from '@utils/constants';


const settingsService = {
  async fetchSavedSettings(settings_name: string) {
    try {
      const response = await apiClient.get(
        `${BACKEND_URL}/settings/${settings_name}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async saveSettings(settings_name: string, settings: SettingsFormData) {
    try {
      const response = await apiClient.post(
        `${BACKEND_URL}/settings/${settings_name}`,
        settings
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default settingsService;
