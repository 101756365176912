import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import HeaderBar from '@components/ChatHeader/HeaderBar';
import CloseButton from '@components/ChatHeader/CloseButton';

function LeumiHeader() {
  const { botName, isEmbeddedModeEnabled } = useSelector(
    (state: RootState) => state.settings
  );

  return (
    <div className='leumi-header'>
      <h3>{botName}</h3>
      {isEmbeddedModeEnabled ? <CloseButton /> : <HeaderBar />}
    </div>
  );
}

export default LeumiHeader;
