import arrowIcon from '@assets/images/arrow.svg';
import { getGradientBackground } from '@utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { toggleOpenChatWindow } from '@states/slices/chatbotSlice';
import './DefaultOpenButton.scss';

function DefaultOpenButton() {
  const { color1, color2 } = useSelector((state: RootState) => state.settings);
  const { openChatWindow } = useSelector((state: RootState) => state.chatbot);
  const dispatch = useDispatch();

  return (
    <>
      <div className='button-container'>
        <button
          className={openChatWindow.toLowerCase()}
          style={{
            background: getGradientBackground({ color1, color2 }),
          }}
          onClick={() => dispatch(toggleOpenChatWindow())}
          aria-label={openChatWindow === 'OPEN' ? 'Close chat window' : 'Open chat window'}
          aria-expanded={openChatWindow === 'OPEN'}
        >
          <img
            src={arrowIcon}
            alt={''}
            className='dropdown-button'
            style={{
              transform: openChatWindow === 'OPEN' ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </button>
      </div>
    </>
  );
}

export default DefaultOpenButton;
