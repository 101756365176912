import { useSelector } from 'react-redux';
import { RootState } from '@/states/store';
import conversationService from '@/services/conversationService';
import Tooltip from '@/components/Tooltip';
import { BiInfoCircle } from 'react-icons/bi';

function TransferButton() {
  const { currentConversation } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { currentLanguage } = useSelector((state: RootState) => state.settings);

  async function handleTransferChat() {
    // For now the transfer flow will not open a modal, but will just update the conversation and open the conversation in a new tab
    const { fieldsUpdated } = await conversationService.updateConversation(
      currentConversation,
      { bot_success: true }
    );
    console.info('Updated conversation: ', fieldsUpdated);
    const conversationLink = `conversation?conversationId=${currentConversation}`;
    window.open(conversationLink, '_blank');
  }

  return (
    <Tooltip content={currentLanguage.transfer.title}>
      <div
        className='transfer-button'
        role='button'
        aria-label='Transfer button'
        tabIndex={0}
        onClick={handleTransferChat}
      >
        <BiInfoCircle />
      </div>
    </Tooltip>
  );
}

export default TransferButton;
