import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import useCurrentSkin from '@hooks/useCurrentSkin';
import {
  isStreamingWaitingInProgress,
  setUserInput,
} from '@states/slices/chatbotSlice';

function EmojiPickerButton() {
  const { chatStatus, userInput } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { isFileSendEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  const isInputStreamingDisabled = useSelector(isStreamingWaitingInProgress);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const currentSkin = useCurrentSkin();
  const dispatch = useDispatch();

  function handleEmojiUploader(emojiData: any) {
    dispatch(setUserInput(userInput + emojiData.emoji));
    setShowEmojiPicker(false);
  }

  return (
    <>
      <button
        className={
          isFileSendEnabled ? 'emoji-button' : 'emoji-button-no-file-upload'
        }
        aria-label={
          showEmojiPicker ? 'Close Emoji Picker' : 'Open Emoji Picker'
        }
        aria-expanded={showEmojiPicker}
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        disabled={chatStatus === 'FINISHED' || isInputStreamingDisabled}
      >
        <img src={currentSkin.assets.emojiIcon} alt='' />
      </button>

      {showEmojiPicker && (
        <EmojiPicker
          onEmojiClick={handleEmojiUploader}
          autoFocusSearch={false}
          emojiStyle={EmojiStyle.NATIVE}
        />
      )}
    </>
  );
}

export default EmojiPickerButton;
