import { useState } from 'react';
import { BiDislike, BiLike, BiSolidDislike, BiSolidLike } from 'react-icons/bi';
import { MessageRating } from '@utils/types';
import conversationService from '@services/conversationService';
import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { LikeButtonsProps } from './LikeButtons.type';
import './LikeButtons.scss';
import TextareaAutosize from 'react-textarea-autosize';
import Tooltip from '../Tooltip';
import Modal from '../Modal';

function LikeButtons({ id, rating }: LikeButtonsProps) {
  const [messageRating, setMessageRating] = useState<MessageRating>(rating);
  const [messageFeedback, setMessageFeedback] = useState('');
  const [selectedRating, setSelectedRating] = useState<MessageRating | null>(null);

  const { currentConversation } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { currentLanguage, isLikeFeedbackEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  function handleOpenModal(rating: MessageRating) {
    const isCheckedRating = messageRating !== rating;
    if (isLikeFeedbackEnabled && isCheckedRating) {
      setSelectedRating(rating);
    } else {
      handleSubmitFeedback(rating);
    }
  }
  function handleCloseModal() {
    setSelectedRating(null);
    setMessageFeedback('');
  }

  async function handleSubmitFeedback(rating: MessageRating) {
    const ratingToSubmit = rating;
    if (ratingToSubmit) {
      const newMessageRating = messageRating === ratingToSubmit ? null : ratingToSubmit;
      setMessageRating(newMessageRating);
      await conversationService.saveMessageRating(
        id,
        newMessageRating,
        messageFeedback,
        currentConversation,
      );
    }
    handleCloseModal(); 
  }

  return (
    <div className='like-dislike-icons'>

     <Tooltip content={currentLanguage.good_response}>
        <button
          className='like-buttons'
          aria-label='like'
          onClick={() => handleOpenModal('positive')}
        >
          {messageRating === 'positive' ? <BiSolidLike aria-hidden="true" /> : <BiLike aria-hidden="true" />}
        </button>
      </Tooltip>
      <Tooltip content={currentLanguage.bad_response}>
        <button
          className='like-buttons dislike-buttons'
          aria-label='dislike'
          onClick={() => handleOpenModal('negative')}
        >
          {messageRating === 'negative' ? <BiSolidDislike aria-hidden="true" /> : <BiDislike aria-hidden="true" />}
        </button>
      </Tooltip>
      {isLikeFeedbackEnabled && (
        <Modal
          title={currentLanguage.feedback.ratingFeedback}
          isOpen={selectedRating !== null}
          onClose={handleCloseModal}
        >
          <div className='feedback-container'>
            <TextareaAutosize
              id='feedback'
              name='feedback'
              className="feedback-input"
              minRows={4}
              maxRows={7}
              value={messageFeedback}
              onChange={(e) => setMessageFeedback(e.target.value)}
              placeholder={currentLanguage.feedback.provideFeedback}
              required
            />
            <button 
              className='big-btn' 
              type='button'
              onClick={() => handleSubmitFeedback(selectedRating)}>
              {currentLanguage.feedback.sendButton}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LikeButtons;
