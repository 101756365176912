import eventsService from '@/services/eventsService';
import {
  setFinalBotReply,
  setIdleTimeout,
  setOpenChatWindow,
} from '@/states/slices/chatbotSlice';
import { RootState } from '@/states/store';
import { useSelector, useDispatch } from 'react-redux';
import { ChatMessage } from '@/utils/types';

export function useIdleEventTimeout() {
  const dispatch = useDispatch();
  const { currentUser, currentConversation, idleTimeout } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { idleEventTimeoutInMinutes } = useSelector(
    (state: RootState) => state.settings
  );
  const IDLE_EVENT_TIMEOUT = idleEventTimeoutInMinutes * 60 * 1000;

  async function handleIdleTimeout() {
    console.info('Handling idle timeout...');

    if (idleTimeout) {
      console.info('Clearing existing idle timeout');
      clearTimeout(idleTimeout);
      dispatch(setIdleTimeout(null));
    }

    const currentTime = Date.now();
    localStorage.setItem('idleTimeoutStart', currentTime.toString());

    const newTimeout = setTimeout(() => {
      console.info('Idle timeout reached. Handling idle event...');
      handleIdleEvent();
    }, IDLE_EVENT_TIMEOUT);

    console.info('New idle timeout set:', newTimeout);

    dispatch(setIdleTimeout(newTimeout as unknown as number));
  }

  function checkIdleTimeout() {
    const idleTimeoutStart = localStorage.getItem('idleTimeoutStart');

    if (!idleTimeoutStart) console.info('No idle timeout start time found');

    if (idleTimeoutStart) {
      const elapsedTime = Date.now() - +idleTimeoutStart;
      if (elapsedTime < IDLE_EVENT_TIMEOUT) {
        const remainingTime = IDLE_EVENT_TIMEOUT - elapsedTime;
        console.info(
          `Restoring idle timeout with ${remainingTime}ms remaining`
        );

        const newTimeout = setTimeout(() => {
          console.info('Idle timeout reached. Handling idle event...');
          handleIdleEvent();
        }, remainingTime);

        dispatch(setIdleTimeout(newTimeout as unknown as number));
      } else {
        console.log(
          'Idle timeout already passed, triggering idle event immediately!'
        );
        handleIdleEvent();
      }
    }
  }

  async function handleIdleEvent() {
    console.info('Handling idle event...');
    try {
      const botReply = await eventsService.handleIdleEvent(
        currentUser,
        currentConversation
      );
      // TODO: Handle other events than just bot replies
      if (botReply) {
        console.info('Idle event response contains a bot reply:', botReply);
        const { message, message_id } = botReply;
        const newBotMessage: ChatMessage = {
          id: message_id,
          text: message,
          pov: 'bot',
          time: new Date().toISOString(),
        };
        dispatch(setFinalBotReply(newBotMessage));
        dispatch(setOpenChatWindow('OPEN'));
      }
      console.info('Idle event handled successfully!');
    } catch (error) {
      console.error('Error handling idle event:', error);
    } finally {
      if (idleTimeout) {
        console.log('Clearing idle timeout after handling idle event');
        clearTimeout(idleTimeout);
        dispatch(setIdleTimeout(null));
      }
      localStorage.removeItem('idleTimeoutStart');
    }
  }

  // TODO: Clearing the timeout on unmount is causing the refreshed idle event to be cleared prematurely when opening the chatbot
  // Do we even need to clear the timeout on unmount?

  // useEffect(() => {
  //   return () => {
  //     console.log('Component unmounting: clearing idle timeout');
  //     if (idleTimeout) {
  //       clearTimeout(idleTimeout);
  //       dispatch(setIdleTimeout(null));
  //     }
  //   };
  // }, []);

  return { handleIdleTimeout, checkIdleTimeout };
}
