import { io } from 'socket.io-client';
import { BACKEND_URL } from './constants';


/**
 * The socketio instance is created withouth the URL param. The URL will fallback to the window.location.host value.
 * This is ok for our V1 and V2 deployment setup - Because the socketio server is hosted on the same domain as the frontend.
 * In case the socketio server is hosted on a different domain, you should provide the URL param.
 *
 * It will require to fetch the socket URL  from a configuration backend API, so we can change the URL without redeploying the frontend.
 * For more information, ask Renzo.
 */

const SOCKET_URL =
  import.meta.env.MODE === 'development' ? BACKEND_URL : window.location.host;

export const socket = io(SOCKET_URL, {
  transports: ['websocket'],
  autoConnect: false,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
});
