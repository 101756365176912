import { RootState } from '@/states/store';
import ChatInput from '@components/ChatBottom/ChatInput';
import EmojiPickerButton from '@components/ChatBottom/EmojiPickerButton';
import FileUploadButton from '@components/ChatBottom/FileUploadButton';
import SendMessageButton from '@components/ChatBottom/SendMessageButton';
import { useSelector } from 'react-redux';

function AlternateChatBottom() {
  const { isFileSendEnabled, isEmojiSendEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  return (
    <>
      <div className='bottom-container-left'>
        {isFileSendEnabled && <FileUploadButton />}
        {isEmojiSendEnabled && <EmojiPickerButton />}
        <ChatInput />
      </div>
      <SendMessageButton />
    </>
  );
}

export default AlternateChatBottom;
