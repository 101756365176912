import { useSelector } from 'react-redux';
import { getGradientBackground } from '@utils/utils';
import { RootState } from '@states/store';
import { getFileSrc } from '@utils/files';
import HeaderBar from '../HeaderBar';
import useCurrentSkin from '@hooks/useCurrentSkin';
import './DefaultHeader.scss';
import BackButton from '../BackButton';

function DefaultHeader() {
  const {
    botName,
    botImage,
    color1,
    color2,
    botNameColor,
    currentLanguage,
    isMobileModeEnabled,
    isOnlineStatusEnabled,
    isFullScreenModeEnabled,
  } = useSelector((state: RootState) => state.settings);

  const currentSkin = useCurrentSkin();

  return (
    <header>
      <div
        className={`default-header ${currentSkin.css.header}`}
        style={
          currentSkin.flags.headerGradient
            ? { background: getGradientBackground({ color1, color2 }) }
            : {}
        }
      >
        <div className='header-sub-container'>
          {isMobileModeEnabled && !isFullScreenModeEnabled && <BackButton />}
          <div className='image-container'>
            <img src={getFileSrc(botImage)} alt='' />
          </div>
          <div className='user-details'>
            <h1 style={botNameColor ? { color: `${botNameColor}` } : {}}>
              {botName}
            </h1>
            {isOnlineStatusEnabled && <p>{currentLanguage.availability}</p>}
          </div>
        </div>
        <HeaderBar />
      </div>
      {currentSkin.flags.onlineNowEnabled ? (
        <div className='online-status-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='8'
            height='8'
            viewBox='0 0 8 8'
            fill='none'
          >
            <circle cx='4.26658' cy='4.23912' r='3.69334' fill='#02C75D' />
          </svg>
          <p>{currentLanguage.online_now}</p>
        </div>
      ) : null}
    </header>
  );
}

export default DefaultHeader;
