import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { useState } from 'react';
import { GoCopy, GoCheck } from 'react-icons/go';
import copy from 'copy-to-clipboard';
import './TransferModal.scss';

function TransferModal() {
  const { currentConversation } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { currentLanguage } = useSelector((state: RootState) => state.settings);

  const [copied, setCopied] = useState(false);

  const adminLink = `${window.location.origin}/admin/chats?conversationId=${currentConversation}`;
  const conversationLink = `conversation?conversationId=${currentConversation}`;

  async function handleCopyLink(link: string) {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(link);
    } else {
      copy(link);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <div className='transfer-container'>
      <p className='transfer-text'>{currentLanguage.transfer.description}</p>
      <div className='transfer-link-container'>
        <p className='transfer-link'>
          <div className='scrollable-content'>{adminLink}</div>
        </p>
        <button
          onClick={() => handleCopyLink(adminLink)}
          className='clipboard-btn'
        >
          {copied ? <GoCheck /> : <GoCopy />}
        </button>
      </div>
      <p className='access-text'>{currentLanguage.transfer.access_history}</p>

      <a
        className='view-conversation-link'
        href={conversationLink}
        target='_blank'
        rel='noopener noreferrer'
      >
        {currentLanguage.transfer.view_history}
      </a>
    </div>
  );
}

export default TransferModal;
