import './LoadingAnimation.scss';

function LoadingAnimation() {
    return (
        <div className="loading-spinner-overlay">
            <div className="loading-spinner"></div>
        </div>
    )
}

export default LoadingAnimation;