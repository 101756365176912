import { configureStore } from '@reduxjs/toolkit';
import settingsReducer from './slices/settingsSlice';
import chatbotReducer from './slices/chatbotSlice';
import filePreviewReducer from './slices/filePreviewSlices';
import skinsReducer from './slices/skinsSlice';

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    chatbot: chatbotReducer,
    filePreview: filePreviewReducer,
    skins: skinsReducer,
  },
});

store.subscribe(() => {
  store.getState();
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
