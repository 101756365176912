import { formatText } from '@/utils/utils'
import { BsPersonCircle } from 'react-icons/bs'
import { HiOutlineSparkles } from 'react-icons/hi2'
import { ConversationListProps } from './ConversationList.type'

function ConversationList ({ messages }: ConversationListProps) {
  return (
    <div className='conversation-list'>
      {messages.map(message => (
        <div className={message.pov} key={message.id}>
          {message.pov === 'bot' ? <HiOutlineSparkles /> : <BsPersonCircle />}
          <p>{formatText(message.text)}</p>
        </div>
      ))}
    </div>
  )
}

export default ConversationList