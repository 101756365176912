import { useDispatch } from 'react-redux';
import { toggleOpenChatWindow } from '@states/slices/chatbotSlice';
import useCurrentSkin from '@hooks/useCurrentSkin';

function CloseButton() {
  const dispatch = useDispatch();
  const currentSkin = useCurrentSkin();

  return (
    <div
      className='modal-close-button'
      role="button"
      aria-label='Close modal'
      tabIndex={0}
      onClick={() => dispatch(toggleOpenChatWindow())}
    >
      {currentSkin.flags.closeIcon ? (
        <img src={currentSkin.assets.closeIcon} alt='' />
      ) : (
        <span>&times;</span>
      )}
    </div>
  );
}

export default CloseButton;
