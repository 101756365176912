import { useDispatch } from 'react-redux';
import { toggleOpenChatWindow } from '@/states/slices/chatbotSlice';
import BackIcon from '@skins/alternate/assets/arrow-right.svg';

function BackButton() {
  const dispatch = useDispatch();
  return (
    <div
      className='back-button'
      role='button'
      aria-label='Close modal'
      tabIndex={0}
      onClick={() => dispatch(toggleOpenChatWindow())}
    >
      <img src={BackIcon} alt='' />
    </div>
  );
}

export default BackButton;
