import { ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import './Tooltip.scss';

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
}


function Tooltip({ children, content, side = 'bottom', align = 'center' }: TooltipProps) {
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Content
          side={side}
          align={align}
          className='RadixTooltip-Content'
        >
          <div className='TooltipContent'>{content}</div>
        </RadixTooltip.Content>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};



export default Tooltip;
