import { useSelector } from 'react-redux';
import { RootState } from '../states/store';
import { skins } from '@skins/index';

function useCurrentSkin() {
  const currentSkinName = useSelector(
    (state: RootState) => state.skins.currentSkinName
  );
  const currentSkin = skins[currentSkinName] || skins.default;
  return currentSkin;
}

export default useCurrentSkin;
